// extracted by mini-css-extract-plugin
export var allLink = "DownloadResources__allLink__K15X9";
export var allResourcesListItem = "DownloadResources__allResourcesListItem__Es2vE";
export var column = "DownloadResources__column__yz9_F";
export var flex = "DownloadResources__flex__tHOOY";
export var flexColumn = "DownloadResources__flexColumn__FeCfq";
export var gap1 = "DownloadResources__gap1__Wnhd9";
export var gap2 = "DownloadResources__gap2__kdyOC";
export var gap3 = "DownloadResources__gap3__P3jIT";
export var gap4 = "DownloadResources__gap4__va7ts";
export var gap5 = "DownloadResources__gap5__KToqb";
export var header = "DownloadResources__header__SeTzj";
export var li = "DownloadResources__li__qvwZj";
export var listItemWrapper = "DownloadResources__listItemWrapper__Krexq";
export var resourceName = "DownloadResources__resourceName__zYfCU";
export var resourceQty = "DownloadResources__resourceQty__lGAsl";
export var row = "DownloadResources__row__oI14L";
export var section = "DownloadResources__section__nApoM";
export var ul = "DownloadResources__ul__T8Cl8";