// extracted by mini-css-extract-plugin
export var column = "BlogNewsletterError__column__uzBVi";
export var fieldError = "BlogNewsletterError__fieldError__FFiH3";
export var flex = "BlogNewsletterError__flex__T66P8";
export var flexColumn = "BlogNewsletterError__flexColumn__k_I39";
export var gap1 = "BlogNewsletterError__gap1__PHvk3";
export var gap2 = "BlogNewsletterError__gap2__MchaH";
export var gap3 = "BlogNewsletterError__gap3__Cb0IP";
export var gap4 = "BlogNewsletterError__gap4__z6mZv";
export var gap5 = "BlogNewsletterError__gap5__HBRhq";
export var row = "BlogNewsletterError__row__QbSHR";