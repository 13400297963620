// extracted by mini-css-extract-plugin
export var allLink = "TopServices__allLink__XCQoS";
export var allResourcesListItem = "TopServices__allResourcesListItem__UFgzU";
export var column = "TopServices__column__ZVhfj";
export var flex = "TopServices__flex__ZHFzw";
export var flexColumn = "TopServices__flexColumn__oOOio";
export var gap1 = "TopServices__gap1__Eu1kz";
export var gap2 = "TopServices__gap2__ozYw4";
export var gap3 = "TopServices__gap3__v7EMP";
export var gap4 = "TopServices__gap4__XD8qf";
export var gap5 = "TopServices__gap5__FklfX";
export var header = "TopServices__header__YPP2y";
export var li = "TopServices__li__qMvfo";
export var listItemWrapper = "TopServices__listItemWrapper__FrCK2";
export var row = "TopServices__row__d6tAq";
export var section = "TopServices__section__fTGar";
export var serviceName = "TopServices__serviceName__YDggv";
export var ul = "TopServices__ul__KPiF1";