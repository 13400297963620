// extracted by mini-css-extract-plugin
export var column = "LatestPosts__column__hVWq1";
export var flex = "LatestPosts__flex__tNaUR";
export var flexColumn = "LatestPosts__flexColumn__wBvy9";
export var gap1 = "LatestPosts__gap1__J1xh9";
export var gap2 = "LatestPosts__gap2__sSdZR";
export var gap3 = "LatestPosts__gap3___K89v";
export var gap4 = "LatestPosts__gap4__WfWxu";
export var gap5 = "LatestPosts__gap5__imzbm";
export var imagePlaceholder = "LatestPosts__imagePlaceholder__koxoa";
export var link = "LatestPosts__link__GaSe9";
export var post = "LatestPosts__post__ZRwE1";
export var row = "LatestPosts__row__oV0ag";
export var section = "LatestPosts__section__cJj1S";
export var text = "LatestPosts__text__AqNda";
export var title = "LatestPosts__title__BaE7x";