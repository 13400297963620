// extracted by mini-css-extract-plugin
export var column = "BlogNewsletter__column__O4DdH";
export var columnOrientation = "BlogNewsletter__columnOrientation__hS_k3";
export var container = "BlogNewsletter__container__uS9oC";
export var email = "BlogNewsletter__email__t_BcQ";
export var flatBorder = "BlogNewsletter__flatBorder__FqOFD";
export var flex = "BlogNewsletter__flex__PEYdY";
export var flexColumn = "BlogNewsletter__flexColumn__RyT78";
export var form = "BlogNewsletter__form__TIuxZ";
export var gap1 = "BlogNewsletter__gap1__rJTqe";
export var gap2 = "BlogNewsletter__gap2__iu9h_";
export var gap3 = "BlogNewsletter__gap3__jZBez";
export var gap4 = "BlogNewsletter__gap4__lPmby";
export var gap5 = "BlogNewsletter__gap5__S_U4F";
export var header = "BlogNewsletter__header__jOqlG";
export var icon = "BlogNewsletter__icon__Fl8zR";
export var leftContent = "BlogNewsletter__leftContent__jyO5H";
export var resultMessage = "BlogNewsletter__resultMessage__X3vk0";
export var rightContent = "BlogNewsletter__rightContent__ORFgs";
export var roundBorder = "BlogNewsletter__roundBorder__U9JHu";
export var row = "BlogNewsletter__row__m1hAm";
export var rowOrientation = "BlogNewsletter__rowOrientation__Y61a_";
export var section = "BlogNewsletter__section__nxkxR";