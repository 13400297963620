// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../styleguide/components/Link/A.res.js";
import * as P from "../../../../styleguide/components/Paragraph/P.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H4 from "../../../../styleguide/components/Heading/H4.res.js";
import * as ID from "../../../../libs/ID.res.js";
import * as Picture from "../../../../styleguide/forms/Picture/Picture.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as LatestPostsScss from "./LatestPosts.scss";

var css = LatestPostsScss;

function LatestPosts(props) {
  var title = props.title;
  var className = props.className;
  var className$1 = className !== undefined ? Cx.cx([
          css.section,
          Belt_Option.getExn(Caml_option.valFromOption(className))
        ]) : css.section;
  return JsxRuntime.jsxs("div", {
              children: [
                title !== undefined ? JsxRuntime.jsx(H4.make, {
                        className: css.title,
                        children: title
                      }) : null,
                Belt_Array.map(props.latestPosts, (function (post) {
                        return JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(A.make, {
                                            href: post.url,
                                            className: css.link,
                                            children: JsxRuntime.jsx(Picture.make, {
                                                  src: post.featuredImage,
                                                  large: [
                                                    60,
                                                    60
                                                  ],
                                                  imageClassName: css.imagePlaceholder
                                                })
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(A.make, {
                                                    href: post.url,
                                                    className: css.link,
                                                    children: post.title
                                                  }),
                                              JsxRuntime.jsx(P.make, {
                                                    children: Format(post.publishedAt, "d MMM yyyy")
                                                  })
                                            ],
                                            className: css.text
                                          })
                                    ],
                                    className: css.post
                                  }, ID.toString(post.id));
                      }))
              ],
              className: className$1
            });
}

var make = LatestPosts;

export {
  css ,
  make ,
}
/* css Not a pure module */
